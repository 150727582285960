@import '_variables';
@import '_mixin';

/**
 * @type		component
 * @name		content-popup
 * @moudle		module-a
 * @update		2022-04-11
 */
 @keyframes content-popup-active {
	0% {
		visibility: hidden;
		opacity: 0;
	}
	100% {
		visibility: visible;
		opacity: 1;
	}
}

@keyframes content-popup-inactive {
	0% {
		visibility: visible;
		opacity: 1;
	}
	100% {
		visibility: hidden;
		opacity: 0;
	}
}

html.active-content-popup {	
	body {
		height: 100%;
		overflow: hidden !important;
	}
}


.content-popup.module-a {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: fixed;
	z-index: 60;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 24rem;
	visibility: hidden;
	opacity: 0;
	background-color: rgba(0,0,0,0.0);
	pointer-events: none;
	transition: visibility 0.25s ease, opacity 0.25s ease;

	&.active {
		visibility: visible;
		opacity: 1;
		pointer-events: auto;
		background-color: rgba(0,0,0,0.4);
	}

	&.medium {
		.popup-page-body {
			width: 480rem;
		}
	}

	.popup-page-body {
		display: flex;
		overflow: hidden;
		position: relative;
		max-width: 100%;
		border-radius: 8rem;
		box-shadow: 0 4rem 8rem 0 rgba(0,0,0,0.15), 0 1rem 2rem 0 rgba(0,0,0,0.1);
		background-color: color(base, 000);
		
		.popup-local {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			width: 100%;
			
			.popup-local-head {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				position: relative;
				z-index: 1;
				padding: 0 20rem;
				height: 64rem;
				box-shadow: 0 1rem 0 0 color(base, 010);

				@include respond-above(large-1x) {
					padding: 0 32rem;
				}
				
				.popup-local-title {
					margin: 0;
					
					.popup-local-name {
						display: block;
						color: color(normal, 100);
						@include font((outline: body, size: small)...);
						@include ellipsis(1);
					}
				}
			}

			.popup-local-body {
				flex-grow: 1;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				
				.popup-content {
					flex-grow: 1;
					display: flex;
					flex-direction: column;
					overflow: hidden;

					.popup-content-body {
						flex-grow: 1;
						padding: 20rem;
						@include overflow-scroll(default, dark, medium, y);
						@include respond-above(large-1x) {
							padding: 32rem;
						}
					}
				}
			}

			.popup-local-func {
				display: flex;
				align-items: center;
				position: absolute;
				z-index: 1;
				top: 0;
				right: 0;
				padding: 0 16rem;
				height: 64rem;

				.button-area {
					display: flex;
					.btn.popup-close {
						flex-shrink: 0;
						svg {
							width: 28rem;
							height: 28rem;
							fill: color(normal, 100);
						}
					}
				}
			}		
		}
	}
}

/**
 * @type		component
 * @name		content-popup
 * @moudle		module-a
 * @update		2022-08-18
 * @subject		고등학교 검색
 */
.content-popup#schoolSearch {
	.popup-content-body {
		height: 240rem;

		.info-board.module-a {
			margin: 56rem 0;
			.board-wrap {
				.board-head {
					.board-summary {
						margin: 0;
						@include font((outline: body, size: small-1x)...);
						color: color(normal, 030);
					}
				}
			}
		}

		.data-table.module-c {
			margin-top: 24rem;
			// height: 240rem;
			// @include overflow-scroll(default, dark, medium, y);
		}
	}
}

/**
 * @type		component
 * @name		content-popup
 * @moudle		module-a
 * @update		2022-08-18
 */
 .content-popup#privacySearch{
	.popup-content-body {
		.info-board.module-a {
			margin: 44rem 0;
			.board-wrap {
				.board-head {
					.board-summary {
						margin: 0;
						@include font((outline: body, size: small-1x)...);
						color: color(normal, 030);

						.wbr {
							display: block;
						}
					}
				}
			}
		}
	}
}