@import '_variables';

@mixin root-selector($new-class) {
	$root-selector: html;
	$current-selector: &;
	$new-selector: ();

	@each $item in $current-selector {
		$first-item: nth($item, 1);

		$appended-item: $root-selector + $new-class + ' ' + $first-item;

		$new-item: set-nth($item, 1, $appended-item);
		$new-selector: append($new-item, $new-selector);
	}

	@at-root #{$new-selector} {
		@content;
	}
}

// 빈 오브젝트
@mixin white-space {
	background-color: rgba(255,0,0,0.125);
}

@mixin material-icons($style: 'Material Icons', $type: 0, $weight: 400, $size: 24rem, $color: &) {
	font-family: $style;
	font-weight: normal;
	font-style: normal;
	font-size:  $size;
	line-height: 1;

	@if $color != & {
		color: $color;
	}

	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	text-decoration: none;
	// Support for all WebKit browsers.
	-webkit-font-smoothing: antialiased;
	// Support for Safari and Chrome.
	text-rendering: optimizeLegibility;
	// Support for Firefox.
	-moz-osx-font-smoothing: grayscale;
	// Support for IE.
	font-feature-settings: 'liga';
}

@mixin material-symbols-outlined($style: 'Material Symbols Outlined', $type: 0, $weight: 400, $size: 24rem, $color: #000) {
	font-family: $style;
	font-weight: normal;
	font-style: normal;
	font-size: $size;
	line-height: 1;
	color: $color;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;

	font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

@mixin font-awesome {
	display: inline-block;
	vertical-align: top;
	width: 1.5em;
	height: 1.5em;
	line-height: 1.5;
	font-size: inherit;
	// font-family: FontAwesome;
    font-family: "Font Awesome 5 Pro";
	font-weight: 900;
	text-align: center;
	
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin clear {
	content: '';
	display: block;
	clear: both;
}

@mixin hidden {
	display: block;
	position: absolute;
	overflow: hidden;
	visibility: hidden;
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	line-height: 1px;
	font-size: 1px;
	border: 0;
	clip: rect(0 0 0 0);
	clip: rect(0,0,0,0);
	pointer-events: none;
}

@mixin image-replace {
	opacity: 0;
	animation-name: text-fadein;
	animation-duration: 1s;
	animation-delay: 1s;
	animation-fill-mode: forwards;
}

@keyframes text-fadein {
	0% {opacity: 0;}
	100% {opacity: 1;}
}


// @inlcude ellipsis(1);
// @inlcude ellipsis(3);
@mixin ellipsis($line-clamp) {
	display: block;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $line-clamp;
	text-overflow: ellipsis;
	max-height: 1.5em * $line-clamp;
	word-wrap: break-word;
	overflow: hidden;
}


// @mixin toggle-hide($duration) {
// 	visibility: hidden;
// 	opacity: 0;
// 	z-index: -1;
// 	transition: visibility $duration ease, opacity $duration ease, z-index $duration ease;
// }
// @mixin toggle-show {
// 	visibility: visible;
// 	opacity: 1;
// }


@mixin container {
	left: 50%;
	transform: translateX(-50%);
	width: 100vw;
	min-width: calc(100% + 128rem);
	// position: absolute;
	// @if ($min-width) {
	// 	min-width: $min-width;
	// }
}

@mixin display-column($column: 1, $column-gap: 2rem, $row-gap: $column-gap) {
	@if $column == 1 {
		width: 100%;
		// &:nth-child(n+1) {
		// 	margin-top: $row-gap;
		// }
	}
	@else if $column == 2 {
		width: calc((100% - #{$column-gap}) / 2);
		// &:nth-child(1) {
		// 	margin-top: $row-gap;
		// }
		&:nth-child(2n) {
			// margin-top: $row-gap;
			margin-left: $column-gap;
		}
		&:nth-child(n+3) {
			margin-top: $row-gap;
		}
	}
	@else if $column == 3 {
		width: calc((100% - (#{$column-gap} * 2)) / 3);
		&:not(:nth-child(3n+1)) {
			margin-left: $column-gap;
		}
		&:nth-child(n+4) {
			margin-top: $row-gap;
		}
	}
	@else if $column == 4 {
		width:calc((100% - (#{$column-gap} * 3)) / 4);
		&:not(:nth-child(4n+1)) {
			margin-left: $column-gap;
		}
		&:nth-child(n+5) {
			margin-top: $row-gap;
		}
	}
	@else if $column == 5 {
		width: calc((100% - (#{$column-gap} * 4)) / 5);
		&:not(:nth-child(5n+1)) {
			margin-left: $column-gap;
		}
		&:nth-child(n+6) {
			margin-top: $row-gap;
		}
	}
	@else if $column == 6 {
		width: calc((100% - (#{$column-gap} * 5)) / 6);
		&:not(:nth-child(6n+1)) {
			margin-left: $column-gap;
		}
		&:nth-child(n+7) {
			margin-top: $row-gap;
		}
	}
	@else if $column == 7 {
		width: calc((100% - (#{$column-gap} * 6)) / 7);
		&:not(:nth-child(7n+1)) {
			margin-left: $column-gap;
		}
		&:nth-child(n+8) {
			margin-top: $row-gap;
		}
	}
	@else if $column == 8 {
		width: calc((100% - (#{$column-gap} * 7)) / 8);
		&:not(:nth-child(8n+1)) {
			margin-left: $column-gap;
		}
		&:nth-child(n+9) {
			margin-top: $row-gap;
		}
	}
}

// overflow scroll
@mixin overflow-scroll($mode: default, $theme: dark, $outline: medium, $scroll: &) {
	// &::-webkit-scrollbar {}
	// &::-webkit-scrollbar-button {}
	// &::-webkit-scrollbar-button:start {}
	// &::-webkit-scrollbar-button:end {}
	// &::-webkit-scrollbar-button:vertical:increment {}
	// &::-webkit-scrollbar-button:vertical:decrement {}
	// &::-webkit-scrollbar-track {}
	// &::-webkit-scrollbar-track-piece {}
	// &::-webkit-scrollbar-corner {}
	// &::-webkit-resizer {}

	@if $mode == default {

		// mobile
		@include root-selector('.mobile') {
			@if $scroll == & {
				overflow: auto;
			}
	
			@if $scroll == x {
				overflow-x: auto;
				overflow-y: hidden;
			}
	
			@if $scroll == y {
				overflow-x: hidden;
				overflow-y: auto;
			}
		}

		// laptop
		@include root-selector('.laptop') {
			-webkit-overflow-scrolling: touch;
	
			&::-webkit-scrollbar-thumb {
				border-width: 4rem;
				border-style: solid;
				border-color: transparent;
				background-clip: content-box;
	
				@if $theme == dark {
					background-color: rgba(0,0,0,0.2);
					&:hover	{background-color: rgba(0,0,0,0.4)};
					&:active{background-color: rgba(0,0,0,0.6)};
				}
				@if $theme == light {
					background-color: rgba(255,255,255,0.4);
					&:hover	{background-color: rgba(255,255,255,0.6)};
					&:active{background-color: rgba(255,255,255,0.8)};
				}
			}
	
			@if $outline == large {
				&::-webkit-scrollbar {
					width: 16rem;
					height: 16rem;
				}
				
				&::-webkit-scrollbar-thumb {
					border-radius: 8rem;
				}
			}
	
			@if $outline == medium {
				&::-webkit-scrollbar {
					width: 12rem;
					height: 12rem;
				}
	
				&::-webkit-scrollbar-thumb {
					border-radius: 6rem;
				}
			}
	
			@if $scroll == & {
				overflow: overlay;
			}
	
			@if $scroll == x {
				overflow-x: overlay;
				overflow-y: hidden;
			}
	
			@if $scroll == y {
				overflow-x: hidden;
				overflow-y: overlay;
			}
		}
	}
}


@mixin tooltipArea($area) {
	@if $area == 'top' {
		top: 0;
		padding-bottom: 1rem;
		.tooltip-area {
			&::before {
				content: '';
				position: absolute;
				top: calc(100% - .0625rem);
				left: calc(50% - (.375rem + .0625rem));
				border-top: calc(.5rem + .0625rem) solid #d2d1d0;
				border-right: calc(.375rem + .0625rem) solid transparent;
				border-bottom: 0 none;
				border-left: calc(.375rem + .0625rem) solid transparent;
			}
		
			&::after {
				content: '';
				position: absolute;
				top: calc(100% - .0625rem);
				left: calc(50% - .375rem);
				border-top: .5rem solid #fff;
				border-right: .375rem solid transparent;
				border-bottom: 0 none;
				border-left: .375rem solid transparent;
			}
		}
	}

	@if $area == 'right' {
		right: -100%;
		.tooltip-area {
			.tooltip-area {
				&::before {
					content: '';
					position: absolute;
					// bottom: calc(100% - 0.0625rem);
					// left: calc(50% - (0.375rem + 0.0625rem));
					border-top: 0 none;
					border-right: calc(0.375rem + 0.0625rem) solid transparent;
					border-bottom: calc(0.5rem + 0.0625rem) solid color(base, 060);
					border-left: calc(0.375rem + 0.0625rem) solid transparent;
				}
				
				&::after {
					content: '';
					position: absolute;
					bottom: calc(100% - 0.0625rem);
					left: calc(50% - 0.375rem);
					border-top: 0 none;
					border-right: 0.375rem solid transparent;
					border-bottom: 0.5rem solid #fff;
					border-left: 0.375rem solid transparent;
				}
			}
		}
	}

	@if $area == 'bottom' {
		bottom: -100%;
		.tooltip-area {
			.tooltip-area {
				&::before {
					content: '';
					position: absolute;
					bottom: calc(100% - 0.0625rem);
					left: calc(50% - (0.375rem + 0.0625rem));
					border-top: 0 none;
					border-right: calc(0.375rem + 0.0625rem) solid transparent;
					border-bottom: calc(0.5rem + 0.0625rem) solid color(base, 060);
					border-left: calc(0.375rem + 0.0625rem) solid transparent;
				}
				
				&::after {
					content: '';
					position: absolute;
					bottom: calc(100% - 0.0625rem);
					left: calc(50% - 0.375rem);
					border-top: 0 none;
					border-right: 0.375rem solid transparent;
					border-bottom: 0.5rem solid #fff;
					border-left: 0.375rem solid transparent;
				}
			}
		}
	}

	@if $area == 'left' {
		left: -100%;
		.tooltip-area {
			.tooltip-area {
				&::before {
					content: '';
					position: absolute;
					bottom: calc(100% - 0.0625rem);
					left: calc(50% - (0.375rem + 0.0625rem));
					border-top: 0 none;
					border-right: calc(0.375rem + 0.0625rem) solid transparent;
					border-bottom: calc(0.5rem + 0.0625rem) solid color(base, 060);
					border-left: calc(0.375rem + 0.0625rem) solid transparent;
				}
				
				&::after {
					content: '';
					position: absolute;
					bottom: calc(100% - 0.0625rem);
					left: calc(50% - 0.375rem);
					border-top: 0 none;
					border-right: 0.375rem solid transparent;
					border-bottom: 0.5rem solid #fff;
					border-left: 0.375rem solid transparent;
				}
			}
		}
	}
}

@mixin content-head($type: style-a) {
	@if $type == 'style-a' {
		.content-head {
			position: relative;
			padding: 32rem;
			border-top-left-radius: 20rem;
			border-top-right-radius: 20rem;
			border-bottom-left-radius: 20rem;
			background-repeat: no-repeat;
			background-position: calc(100% + 480rem) calc(100% + 480rem), left top;
			background-size: 680rem 680rem, cover;
			background-image: url(./../images/layout/content-head.png), linear-gradient(120deg, #f77e2e 16%, #ff9000 94%);
			background-color: #ff9000;

			// laptop
			@include respond-above(large-1x) {
				padding: 36rem 64rem;
				background-position: calc(100% + 700rem) calc(100% + 780rem), left top;
				background-size: 1024rem 1024rem, cover;
			}
	
			&::after {
				content: '';
				display: block;
				clear: both;
			}
	
			.content-subject {
				margin: 0;
				@include font((outline: head, size: large, weight: bold)...);
				color: color(normal, 000);
	
				// laptop
				@include respond-above(large-1x) {
					float: left;
					width: 192rem;
					@include font((outline: head, size: large-1x)...);

					.wbr {
						display: block;
					}
				}
	
				.content-name {
					display: block;
				}
			}
	
			.content-summary {
				margin: 0;
				@include font((outline: body, size: small)...);
				color: color(normal, 000);
	
				// laptop
				@include respond-below(large-1x) {
					margin-top: 20rem;
				}
				
				// laptop
				@include respond-above(large-1x) {
					float: right;
					width: calc(100% - 212rem);
					@include font((outline: body, size: medium)...);

					.wbr {
						display: block;
					}
				}
	
			}
			.content-caption {
				margin: 8rem 0 0 0;
				@include font((outline: body, size: small-2x)...);
				color: color(normal, 000, 0.8);
	
				// laptop
				@include respond-above(large-1x) {
					float: right;
					width: calc(100% - 212rem);
					@include font((outline: body, size: small-1x)...);

					.wbr {
						display: block;
					}
				}
			}
		}
	}
}

@mixin swiper-func {
	display: flex;
	justify-content: center;
	align-items: center;

	[class*=swiper-navigation-] {
		width: 40rem;
		height: 40rem;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	.swiper-button-disabled {
		opacity: 0.4;
	}
	
	.swiper-navigation-prev {
		background-image: icon(chevron-left, color(normal, 000));
	}
	
	.swiper-navigation-next {
		background-image: icon(chevron-right, color(normal, 000));
	}

	.swiper-pagination-data {
		width: initial;
		text-align: center;
		@include font((outline: body, size: small-2x, weight: bold)...);

		.swiper-pagination-current {
			display: block;
			color: color(base, 000);
		}
		
		.swiper-pagination-total {
			display: block;
			padding: 0 8rem;
			border-top: 1px solid color(base, 000, 0.4);
			color: color(base, 000, 0.4);
		}
	}
}