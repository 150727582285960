@import 'partials/_variables';
@import 'partials/_mixin';
@import 'partials/_popup';

// mobile
@include respond-below(large-1x) {
}

// laptop
@include respond-above(large-1x) {
}

:root {
	height: 100%;
	font-size: 1px;

	@include respond-below(small) {
		font-size: 0.75px;
	}
}

body {
	margin: 0;
	padding: 0;
	height: 100%;
	word-break: keep-all;
	@include font((family: default)...);
	-webkit-text-size-adjust: none;
	@include overflow-scroll(default, dark, large, y);
}

hr {
	display: none;
}

img {
	max-width: 100%;
}

/**
 * @type layout
 * @name global
 */
#wrap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	min-height: 100vh;
	overflow: hidden;
	
	// laptop
	@include respond-above(large-1x) {
		padding: 0 64rem;
	}	
	
	#page {
		flex-grow: 1;
		// flex-basis: 100%;
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		padding-top: 60rem;
		width: 1200rem;
		max-width: 100%;
		min-height: 100vh;
		box-sizing: border-box;

		// laptop
		@include respond-above(large-1x) {
			padding-top: 168rem;
		}
	}
}

/**
 * @type layout
 * @name page-skip
 */
.page-skip {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 100;
	top: -120rem;
	right: 0;
	left: 0;
	height: 60rem;
	background-color: color(base, 090);

	// laptop
	@include respond-above(large-1x) {
		height: 80rem;
	}

	.skip-item {
		color: color(base, 000);
		@include font((outline: body, size: small-1x)...);
	}
	
	&:focus-within {
		top: 0;
	}
}

/**
 * @type layout
 * @name page-head
 */
.page-head {
	display: flex;
	position: fixed;
	z-index: 30;
	top: 0;
	left: 0;
	right: 0;

	&::after {
		content: '';
		display: block;
		position: absolute;
		z-index: -1;
		top: 0;
		left: calc(50% - 50vw);
		bottom: 0;
		width: 100vw;
		box-shadow: inset 0 -1rem 0 0 color(base, 020);
		background-color: color(base, 000);
	}

	.page-subject {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		margin: 0;

		.page-name {
			display: block;

			svg {
				display: block;

				.st1 {fill:#9F0D3E;}
				.st2 {fill:#3E3A39;}
			}
		}

		.page-control {
			flex-shrink: 0;
		}
	}

	// mobile
	@include respond-below(large-1x) {
		padding: 0 16rem;
		height: 60rem;
		align-items: center;

		.page-subject {
			.page-name {
				svg {
					width: 156rem;
					height: 36rem;
				}
			}
		}

		.page-control {
			flex: 1 1 100%;
			display: flex;
			justify-content: flex-end;
		}
	}

	// laptop
	@include respond-above(large-1x) {
		margin: 0 auto;
		width: 1200rem;
		max-width: calc(100% - 128rem);
		padding: 0 64rem;
		flex-direction: column;

		&::before {
			flex: 1 1 100%;
			content: '';
			display: block;
		}

		.page-subject {
			justify-content: center;
			height: 104rem;

			.page-name {
				svg {
					width: 208rem;
					height: 48rem;
				}
			}
		}

		.page-control {
			position: absolute;
			height: 104rem;
			top: 0;
			right: 64rem;
		}
	}

	.page-control {
		display: flex;
		align-items: center;

		.button-area {
			display: flex;

			.btn {
				flex-shrink: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 40rem;
				height: 40rem;
				border-radius: 50%;
				padding: 0 8rem;
				box-sizing: border-box;
				@include font((outline: head, size: small-6x, weight: bold)...);
				color: color(normal, 000);
				text-align: center;
				text-decoration: none;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 24rem;

				+.btn {
					margin-left: 8rem;
				}

				&.hot-issue {
					background-color: color(normal, 020);

					&.active {
						background-image: icon(magnifying-glass, color(normal, 000));
	
						.btn-text {
							@include hidden;
						}
	
						&.active {
							background-image: icon(close, color(normal, 000));
						}
					}

					// laptop
					@include respond-above(large-1x) {
						position: fixed;
						z-index: 31;
						right: 0;
						top: 104rem;
						width: 64rem;
						height: 64rem;
						border-radius: 0;
						@include font((outline: head, size: small-3x, weight: bold)...);
						background-size: 32rem 32rem;
					}
				}

				&.unified-search {
					background-color: color(accent, 020);
					background-image: icon(magnifying-glass, color(normal, 000));

					.btn-text {
						position: relative;
						z-index: -1;
					}

					&.active {
						background-image: icon(close, color(normal, 000));
					}
				}

				&.full-menu {
					background-color: color(accent, 010);
					background-image: icon(menu, color(normal, 000));

					.btn-text {
						position: relative;
						z-index: -1;
					}

					&.active {
						background-image: icon(close, color(normal, 000));
					}
				}
			}
		}
	}
}

/**
 * @type layout
 * @name unified-search
 */
html.active-unified-search {
	body {
		height: 100%;
		overflow: hidden;
	}
}

.data-finder.unified-search {
	position: fixed;
	z-index: 30;
	top: 60rem;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: color(normal, 100, 0.6);

	visibility: visible;
	opacity: 1;
	pointer-events: auto;
	transition: visibility 0.25s ease, opacity 0.25s ease, background-color 0.5s ease;

	// laptop
	@include respond-above(large-1x) {
		top: 168rem;
	}

	&:not(.active) {
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		background-color: color(normal, 100, 0);

		.submit-form {
			opacity: 0;
		}
	}

	.submit-form {
		display: flex;
		justify-content: center;
		margin: 0;
		border: hidden;
		background-color: color(normal, 000);
		opacity: 1;
		transition: opacity 0.25s ease;
		
		legend {
			@include hidden;
		}
		
		.form-list {
			padding: 48rem 20rem;
			width: 100%;

			// laptop
			@include respond-above(large-1x) {
				width: 640rem;
				padding: 68rem 20rem;
			}


			.form-item {
				.form-wrap {
					.form-head {
						@include hidden;
					}

					.form-body {
						display: flex;
						flex-direction: column;

						.form-area {
							display: flex;
							
							>*+* {
								margin-left: 4rem;
							}

							.flex {
								flex-grow: 1;
								// flex: 1 1 100%;
							}

						}
						
						.form-noti {
							margin-top: 8rem;

							// laptop
							@include respond-above(large-1x) {
								margin-top: 16rem;
							}
						}
					}
				}
			}
		}
	}
}


/**
 * @type layout
 * @name full-menu
 */
html.active-full-menu {
	body {
		height: 100%;
		overflow: hidden;
	}
}

.section.full-menu {
	position: fixed;
	z-index: 30;
	opacity: 1;
	pointer-events: auto;
	background-color: color(normal, 100, 0.6);
	transition: visibility 0.25s ease, opacity 0.25s ease, background-color 0.25s ease;

	&:not(.active) {
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		background-color: color(normal, 100, 0);
	}

	.section-wrap {
		background-color: color(normal, 000);

		.section-head {
			@include hidden;
		}

		.section-body {
			.gnb-list {
				margin: 0;
				padding: 0;
				list-style: none;

				.lnb-list {
					list-style: none;
				}
			}
		}
	}
	// mobile
	@include respond-below(large-1x) {
		top: 60rem;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: color(normal, 000);
		overflow-y: auto;

		&:not(.active) {
			visibility: hidden;
			opacity: 0;
			pointer-events: none;
		}

		.section-wrap {
			.section-head {
				.section-subject {
					@include hidden;
				}
			}

			.section-body {
				.gnb-list {
					margin: 0;
					padding: 0;
					list-style: none;
	
					.gnb-item {
						display: flex;
						align-items: flex-start;
						flex-direction: column;
						position: relative;
						
						+.gnb-item {
							border-top: 1rem solid color(base, 020);
						}
	
						.btn.expand {
							position: absolute;
							top: calc((56rem / 2) - 12rem);
							right: 12rem;
							width: 24rem;
							height: 24rem;
	
							svg {
								fill: color(normal, 010);
							}
						}
	
						.gnb-name {
							display: flex;
							align-items: center;
							position: relative;
							margin: 0 20rem;
							height: 56rem;
							@include font((outline: body, size: small-1x, weight: medium)...);
							color: color(normal, 010);
							text-decoration: none;
						}
	
						.lnb-list {
							display: flex;
							flex-direction: column;
							list-style: none;
							margin: 0;
							padding: 20rem;
							width: 100%;
							box-sizing: border-box;
							border-top: 2rem solid color(accent, 010);
							background-color: color(base, 010);
	
							.lnb-item {
								display: flex;

								+.lnb-item {
									margin-top: 16rem;
								}
	
								&::before {
									content: '';
									width: 24rem;
									height: 24rem;
									background: icon(bullet-dot, color(normal, 010)) no-repeat center/cover;
								}
	
								.lnb-name {
									display: block;
									@include font((outline: body, size: small-1x)...);
									color: color(normal, 010);

									&:not(:hover) {
										text-decoration: none;
									}
								}

								&.current {
									&::before {
										background: icon(bullet-dot, color(accent, 010)) no-repeat center/cover;
									}
									.lnb-name {
										color: color(accent, 010);
									}
								}
							}
						}
	
						&.current {
							.gnb-name {
								color: color(accent, 010);
							}

							.btn.expand {
								svg {
									fill: color(accent, 010);
								}
							}
						}

						&:not(.active) {
							.lnb-list {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	// laptop
	@include respond-above(large-1x) {
		top: 168rem;
		bottom: 0;
		right: 0;
		left: 0;
		
		.section-wrap {
			position: relative;
			margin: 0 auto;
			width: 1200rem;
			max-width: calc(100% - 128rem);
			padding: 0 64rem;

			&::after {
				content: '';
				display: block;
				position: absolute;
				z-index: -1;
				top: 0;
				left: calc(50% - 50vw);
				bottom: 0;
				width: 100vw;
				box-shadow: 0 1rem 0 0 color(base, 020);
				background-color: color(base, 000);
			}
			
			.section-body {
				.gnb-list {
					display: flex;
					padding: 32rem 0 64rem;

					.gnb-name {
						@include hidden;
					}

					.gnb-item {
						flex: 1 1 100%;

						&.current {
							.gnb-name {
								@include font((weight:bold)...);
								color: color(accent, 010);
							}
						}

						.btn.expand {
							display: none;
						}

						.lnb-list {
							margin: 0;
							padding: 0;
							list-style: none;
	
							.lnb-item {
								+.lnb-item {
									margin-top: 20rem;
								}

								@include font((outline: body, size: small-1x)...);
								text-align: center;
								
								.lnb-name {
									display: flex;
									justify-content: center;
									color: color(normal, 010);
									
									&:not(:hover) {
										text-decoration: none;
									}

									&:hover {
										color: color(accent, 010);
									}

									&[target=_blank] {
										&::after {
											flex-shrink: 0;
											content: '';
											display: block;
											width: 1.5em;
											height: 1.5em;
											margin-left: 4rem;
											background-image: icon(new-open, color(normal, 040));
										}
									}
								}

								&.current {
									.lnb-name {
										color: color(accent, 010);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
 * @type layout
 * @name page-navi
 */

.page-navi {
	position: relative;
	z-index: 30;

	// mobile
	@include respond-below(large-1x) {
		display: none;
	}

	.section-wrap {
		.section-head {
			.section-subject {
				@include hidden;
			}
		}
		.section-body {
			.gnb-list {
				flex: 1 1 100%;
				display: flex;
				position: relative;
				margin: 0;
				padding: 0;
				list-style: none;
				border-right: 1rem solid color(base, 020);
				border-left: 1rem solid color(base, 020);

				&::before {
					content: '';
					display: block;
					position: absolute;
					z-index: -1;
					top: 0;
					bottom: 0;
					left: calc(50% - 50vw);
					width: 100vw;
					height: 1rem;
					background-color: color(base, 020);
				}
				
				.gnb-item {
					display: flex;
					flex: 1 1 100%;
					flex-direction: column;
					position: relative;
					text-align: center;

					+.gnb-item {
						border-left: 1rem solid color(base, 020);
					}
					
					&:focus-within {
						z-index: 1;

						.lnb-list {
							display: flex;
						}
						.gnb-name {
							@include font((weight: medium)...);
							color: color(accent, 010);
							transition: 0.25s;
							&::before {
								content: '';
								display: block;
								position: absolute;
								right: 0;
								left: 50%;
								bottom: 0;
								transform: translateX(-50%);
								width: 0;
								height: 4rem;
								background-color: color(accent, 010);
								width: 100%;
								background-color: color(accent, 010);
								transition: 0.25s linear;
							}
						}
					}

					&:hover {
						z-index: 1;

						.lnb-list {
							display: flex;
						}
						.gnb-name {
							@include font((weight: medium)...);
							color: color(accent, 010);
							transition: 0.25s;
							&::before {
								content: '';
								display: block;
								position: absolute;
								right: 0;
								left: 50%;
								bottom: 0;
								transform: translateX(-50%);
								width: 0;
								height: 4rem;
								background-color: color(accent, 010);
								width: 100%;
								background-color: color(accent, 010);
								transition: 0.25s linear;
							}
						}
					}


					.gnb-name {
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
						height: 64rem;
						text-decoration: none;
						@include font((outline: body, size: small-1x)...);
						color: color(normal, 010);
						

						&::before {
							content: '';
							display: block;
							position: absolute;
							right: 0;
							left: 50%;
							bottom: 0;
							transform: translateX(-50%);
							width: 0;
							height: 4rem;
							background-color: color(accent, 010);
						}
					}

					.btn.expand {
						display: none;
					}

					.lnb-list {
						position: absolute;
						top: 100%;
						right: -1rem;
						left: -1rem;
						margin: 0;
						padding: 20rem 0;
						list-style: none;
						display: none;
						flex-direction: column;
						background-color: color(normal, 000);
						border-bottom-left-radius: 16rem;
						border-bottom-right-radius: 16rem;
						border: 1rem solid color(base, 010);

						.lnb-item {

							+.lnb-item {
								margin-top: 20rem;
							}

							.lnb-name {
								@include font((outline: body, size: small-1x)...);
								color: color(normal, 010);
								&:not(:hover) {
									text-decoration: none;
								}

							}

							&.current {
								.lnb-name {
									color: color(accent, 010);
								}
							}

							&:focus-within {
								.lnb-name {
									color: color(accent, 010);
								}
							}

							&:hover {
								.lnb-name {
									color: color(accent, 010);
								}
							}
						}
					}
				}

				&:not(:hover) {
					.gnb-item {
						&.current {
							.gnb-name {
								@include font((weight: medium)...);
								color: color(accent, 010);
	
								&::before {
									content: '';
									display: block;
									position: absolute;
									right: 0;
									left: 50%;
									bottom: 0;
									transform: translateX(-50%);
									width: 100%;
									height: 4rem;
									background-color: color(accent, 010);
									transition: 0.25s linear;
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
 * @type layout
 * @name hot-issue
 */
html.active-hot-issue {
	body {
		height: 100%;
		overflow: hidden;
	}
}

.section.hot-issue {
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 30;
	top: 60rem;
	left: 0;
	bottom: 0;
	right: 0;
	box-sizing: border-box;
	visibility: visible;
	@include overflow-scroll(default, light, medium, y);
	outline: none;
	opacity: 1;
	pointer-events: auto;
	background-color: color(normal, 020);
	transition: visibility 0.25s ease, opacity 0.25s ease, background-color 0.25s ease;

	// laptop
	@include respond-above(large-1x) {
		top: 168rem;
		@include overflow-scroll(default, light, large, y);
	}
	
	&:not(.active) {
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		background-color: color(normal, 020, 0);
	}
	
	.section-wrap {
		flex: 1 1 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 40rem 16rem 80rem 16rem;
		width: 100%;
		max-width: 1200rem;
		box-sizing: border-box;
		position: relative;

		// laptop
		@include respond-above(large-1x) {
			padding: 84rem 40rem 120rem 40rem;
		}

		.section-head {
			margin-bottom: 16rem;
			text-align: center;

			.section-caption {
				margin: 0;
				@include font((outline: body, size: small-2x, weight: medium)...);
				color: color(normal, 030);

				// laptop
				@include respond-above(large-1x) {
					@include font((outline: body, size: small)...);
				}
			}

			.section-subject {
				margin: 0;
				@include font((outline: head, size: medium, weight: bold)...);
				color: color(normal, 000);

				// laptop
				@include respond-above(large-1x) {
					@include font((outline: head, size: large-4x)...);
				}

				.section-name {
					display: block;
				}
			}
		}

		.section-body {
			width: 100%;
			overflow: hidden;
			flex-shrink: 0;


			.subsection {
				.subsection-wrap {
					.subsection-head {
						@include hidden;
					}
				}

				.data-display {
					.data-list {
						.data-item {
							min-height: 122rem;
						}
					}
				}
			}
			
			.subsection.admission-helper {
				.subsection-wrap {
					.subsection-body {
						position: relative;
						padding-top: 48rem;

						// laptop
						@include respond-above(large-1x) {
							padding-top: 56rem;
						}
					}
				}
				.data-display {
					.data-list {
						display: flex;
						flex-wrap: wrap;

						.data-item {
							// mobile
							@include respond-below(large-1x) {
								@include display-column(2, 8rem, 8rem);
							}
							
							// laptop
							@include respond-above(large-1x) {
								@include display-column(4, 20rem, 20rem);
								height: 168rem;
							}

							.data-wrap {
								height: 100%;
		
								.data-body {
									.para {
										margin: 0;
										@include ellipsis(3);
									}
								}
							}
						}
					}
				}
				.data-display.swiper {
					overflow: hidden;
					height: 512rem;
					
					// laptop
					@include respond-above(large-1x) {
						height: 404rem;
					}
		
					.data-list.swiper-wrapper {
						.data-item.swiper-slide {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 50%;
							height: 122rem;
		
							// laptop
							@include respond-above(large-1x) {
								width: 25%;
								height: 192rem;
							}
		
							.data-wrap {
								height: 100%;
		
								.data-body {
									.para {
										margin: 0;
										@include ellipsis(3);
									}
								}
							}
						}
					}
				}
				.swiper-func {
					position: absolute;
					top: 0;
					right: 0;
					@include swiper-func;
					// background-color: red;
				}
			}
		}

		.section-func {
			display: flex;
			width: 100%;
			flex: 1 1 100%;
			margin-top: 20rem;
			// laptop
			@include respond-above(large-1x) {
				margin-top: 24rem;
			}
			.button-area {
				display: flex;
				flex: 1 1 100%;
				justify-content: center;
				.btn {

					// mobile
					@include respond-below(large-1x) {
					flex: 1 1 100%;
					}

					// laptop
					@include respond-above(large-1x) {
						flex-basis: 160rem;
					}
				}
			}
		}

		// .section-func {
		// 	// laptop
		// 	@include respond-above(large-1x) {
		// 		position: fixed;
		// 		right: 0;
		// 		top: 104rem;
		// 	}
			
		// 	.button-area {
		// 		.btn.popup-close {
		// 			display: flex;
		// 			justify-content: center;
		// 			align-items: center;
		// 			width: 64rem;
		// 			height: 64rem;
					
		// 			svg {
		// 				width: 32rem;
		// 				height: 32rem;
		// 				fill: color(normal, 000);
		// 			}

		// 			background-color: color(normal, 020);
		// 		}
		// 	}
		// }
	}
}

/**
 * @type layout
 * @name page-body
 */
.page-body {
	flex-grow: 1;
	position: relative;
	z-index: 10;
	
	// mobile
	@include respond-below(large-1x) {
		padding: 0 20rem;
	}

	#local {	
		&::before {
			content: '';
			display: block;
			position: absolute;
			z-index: -1;
			top: 0;
			bottom: 0;
			left: calc(50% - 50vw);
			width: 100vw;
			height: 120rem;
			background-color: rgb(0, 0, 0, 0.1);

			// laptop
			@include respond-above(large-1x) {
				height: 150rem;
			}

		}
		.local-head {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			// position: relative;
			height: 120rem;

			// &::before {
			// 	content: '';
			// 	display: block;
			// 	position: absolute;
			// 	z-index: -1;
			// 	top: 0;
			// 	bottom: 0;
			// 	left: calc(50% - 50vw);
			// 	width: 100vw;
			// 	background-color: color(base, 100);
			// 	background-color: rgb(0, 0, 0, 0.1);
			// }
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: -310rem;
				left: -380rem;
				width: 460rem;
				height: 460rem;
				background-image: url(../images/svg/bg_dot.svg);
				opacity: 0.12;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;

				// laptop
				@include respond-below(large-1x) {
					width: 195rem;
					height: 195rem;
					top: -110rem;
					left: -40rem;
				}
			}

			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 30rem;
				right: -380rem;
				width: 460rem;
				height: 460rem;
				background-image: url(../images/svg/bg_dot.svg);
				opacity: 0.12;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;

				// laptop
				@include respond-below(large-1x) {
					width: 195rem;
					height: 195rem;
					top: 40rem;
					right: -40rem;
				}
			}

			// laptop
			@include respond-above(large-1x) {
				height: 150rem;
			}

			.local-subject {
				margin: 0;
				text-align: center;

				.local-name {
					display: block;
					@include font((outline: head, size: large-1x)...);
					color: color(normal, 000);

					// laptop
					@include respond-above(large-1x) {
						@include font((outline: head, size: large-2x)...);
					}
				}
			}
		}

		.local-info {
			display: flex;
			position: relative;
			z-index: 10;

			@include respond-below(large-1x) {
				margin: 0 -20rem;
			}
		
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: calc(50% - 50vw);
				width: 100vw;
				height: 1rem;
				background-color: color(base, 020);
			}
		
			&::after {
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				left: calc(50% - 50vw);
				width: 100vw;
				height: 1rem;
				background-color: color(base, 020);
			}
		
			// 현재위치 보조기능
			.section.breadcrumb {

				// mobile
				@include respond-below(large-1x) {
					flex: 1 1 100%;
				}
		
				.section-wrap {
					.section-head {
						@include hidden;
					}
					.section-body {
						.data-display {
							border: 1rem solid color(base, 020);
					
							.data-list {
								display: flex;
					
								// mobile
								@include respond-below(large-1x) {
									flex: 1 1 100%;
								}
					
								.data-item {
									position: relative;
					
									// mobile
									@include respond-below(large-1x) {
										flex: 1 1 100%;
									}

									&:focus-within {
										.data-wrap {
											.data-body {
												display: block;
											}
										}
									}

									&:hover {
										.data-wrap {
											.data-body {
												display: block;
											}
										}
									}
					
									&:first-child {
										z-index: 2;
										border-right: 1rem solid transparent;
					
										// laptop
										@include respond-below(large-1x) {
											display: none;
										}
					
										.data-wrap {
											.data-head {
												.data-name {
													display: flex;
													justify-content: center;
													align-items: center;
													position: relative;
													width: 46rem;
													height: 46rem;
													@include font((outline: body, size: small-2x)...);
													color: color(normal, 000);
													text-decoration: none;
													background-color: color(accent, 010);
					
													&::before {
														content: '';
														display: block;
														position: absolute;
														top: -1rem;
														right: -1rem;
														bottom: -1rem;
														left: -1rem;
														background: color(accent, 010) icon(house, color(normal, 000)) no-repeat center/24rem;
													}
												}
											}
										}
									}
					
									&:nth-child(n+3) {
										border-left: 1rem solid color(base, 020);
									}
					
									+.data-item {
										// laptop
										@include respond-above(large-1x) {
											width: 184rem;
										}
					
										.data-wrap {
											.data-head {
												.data-name {
													display: flex;
													align-items: center;
													justify-content: space-between;
													padding: 0 12rem;
													height: 46rem;
													@include font((outline: body, size: small-2x)...);
													color: color(normal, 020);
													text-decoration: none;
					
													&::after {
														content: '';
														display: block;
														margin-left: 8rem;
														width: 20rem;
														height: 20rem;
														background: icon(chevron-down, color(normal, 020)) no-repeat center/cover;
													}
												}
											}
										}

										
										&.selected {
											.data-wrap {
												.data-head {
													.data-name {
														@include font((outline: body, size: small-2x)...);
														color: color(normal, 030);

														&::after {
															background: icon(chevron-down, color(normal, 030)) no-repeat center/cover;
														}
													}
												}
											}
										}
									}
									
									.data-wrap {
										.data-head {
											position: relative;
											z-index: 2;
										}
					
										.data-body {
											display: none;
											position: absolute;
											z-index: 1;
											left: -1rem;
											right: -1rem;
					
											.navi-list {
												display: flex;
												flex-direction: column;
												padding: 20rem; 
												border: 1px solid color(base, 020);
												border-bottom-right-radius: 20rem;
												border-bottom-left-radius: 20rem;
												background-color: color(normal, 000);
					
												.navi-item {
													display: flex;

													+.navi-item {
														margin-top: 20rem;
													}
					
													.navi-text {
														display: flex;
														@include font((outline: body, size: small-2x)...);
														color: color(normal, 020);
					
														&:not(:hover) {
															text-decoration: none;
														}
					
														&[target=_blank] {
															&::after {
																content: '';
																display: block;
																margin-left: 4rem;
																width: 20rem;
																height: 20rem;
																background: icon(new-open, color(normal, 040)) no-repeat center/cover;
															}
														}
													}
												}
					
												.navi-item.current {
													.navi-text {
														@include font((weight: medium)...);
														color: color(accent, 010);
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		
			// 브라우저 도구
			.section.browser-toolbar {
				flex: 1 1 100%;

				// laptop
				@include respond-below(large-1x) {
					display: none;
				}
		
				.section-wrap {
					.section-head {
						@include hidden;
					}
					.section-body {
						.button-display {			
							.button-area {
								display: flex;
								justify-content: flex-end;
					
								.btn {
									display: flex;
									justify-content: center;
									align-items: center;
									position: relative;
									width: 48rem;
									height: 48rem;
									margin-left: -1rem;
									@include font((outline: body, size: small-2x, family: default)...);
									border: 1rem solid color(base, 020);
					
									&:focus {
										z-index: 1;
									}
					
									&::before {
										content: '';
										display: block;
										position: absolute;
										top: 1rem;
										right: 1rem;
										bottom: 1rem;
										left: 1rem;
										background-repeat: no-repeat;
										background-position: center;
										background-size: 24rem;
										background-color: color(normal, 000);
					
									}
					
									&.zoom-in {
										&::before {
											background-image: icon(plus, color(normal, 020));
										}
									}
									&.zoom-out {
										&::before {
											background-image: icon(minus, color(normal, 020));
										}
									}
									&.reset {
										&::before {
											background-image: icon(rotate-arrow, color(normal, 020));
										}
									}
									&.print {
										&::before {
											background-image: icon(print, color(normal, 020));
										}
									}
								}
							}
						}			
					}
				}
			}
		}
	}
}

/**
 * @type layout
 * @name page-foot
 */
.page-foot {
	flex-shrink: 0;
	position: relative;
	padding: 24rem 16rem calc(40rem + 56rem) 16rem;
	background-color: color(base, 080);

	// laptop
	@include respond-above(large-1x) {
		padding: 48rem 560rem 48rem 0;
	}

	&::before {
		content: '';
		display: block;
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		left: calc(50% - 50vw);
		width: 100vw;
		background-color: color(base, 080);
	}

	// 툴바
	.section.widget-toolbar {
		position: fixed;
		z-index: 20;

		// mobile
		@include respond-below(large-1x) {
			bottom: 0;
			right: 0;
			left: 0;

			.section-wrap {
				.section-head {
					@include hidden;
				}
			
				.section-body {
	
					.navi-list {	
						display: flex;	
						justify-content: space-around;
						padding: 14rem 0;
						background-color: color(normal, 010);
						.navi-item {
							position: relative;
							justify-content: center;
							display: flex;
							flex: 1 1 100%;
							.navi-text {
								display: flex;
								text-decoration: none;
								@include font((outline: body, size: small, weight: bold)...);
								color: color(normal, 000);
							}
		
							+.navi-item {
								align-items: center;
								&::before {
									display: block;
									content: '';
									width: 6rem;
									height: 6rem;
									position: absolute;
									left: -3rem;
									background-color: color(normal, 040);
									border-radius: 50%;
								}
							}
						}
					}
				}
			}
		}

		// laptop
		@include respond-above(large-1x) {
			top: calc(50% - 120rem);
			right: calc(50% - 600rem - 72rem - 40rem);

			.section-wrap {
				.section-head {
					@include hidden;
				}
			
				.section-body {
					position: relative;
					
					&::before {
						content: '';
						display: block;
						position: absolute;
						bottom: 100%;
						bottom: calc(100% - 12rem);
						left: 0;
						width: 80rem;
						height: 80rem;
						// background-color: rgba(255,0,0,0.1);
						background: url(../images/svg/duksung_bird.svg)no-repeat center/cover;
					}

					.navi-list {
						display: flex;
						flex-direction: column;
						padding: 32rem 12rem 24rem 12rem;
						width: 72rem;
						border-radius: 36rem;
						box-sizing: border-box;
						border: 3rem solid color(accent, 010);
						background-color: color(normal, 000);

						.navi-item {
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;
							text-align: center;

							+.navi-item {
								margin-top: 20rem;
							}

							.navi-text {
								display: block;
								text-decoration: none;
								@include font((outline: body, size: small-1x, weight: bold)...);
								color: color(normal, 010);
							}
		
							+.navi-item {
								&::before {
									display: block;
									content: '';
									position: absolute;
									left: calc(50% - 3rem);
									bottom: calc(100% + 8rem);
									width: 6rem;
									height: 6rem;
									border-radius: 50%;
									background-color: color(normal, 040);
								}
							}
						}
					}
				}
			}
		}
	}
	
	.section.service-policy {
		// laptop
		@include respond-above(large-1x) {
			position: absolute;
			top: 48rem;
			right: 0;
			width: 520rem;
		}

		.section-head {
			@include hidden;
		}

		.section-body {
			.navi-list {	
				display: flex;	
				flex-wrap: wrap;

				.navi-item {
					display: flex;
					flex: 1 1 50%;
					@include font((outline: body, size: small-2x)...);
					color: color(normal, 040);

					&:nth-child(n+3) {
						margin-top: 16rem;

						// laptop
						@include respond-above(large-1x) {
							margin-top: 20rem;
						}

					}

					.navi-text {
						display: block;
						color: inherit;

						&:not(:hover) {
							text-decoration: none;
						}
					}

					&::before {
						display: block;
						content: '';
						width: 20rem;
						height: 20rem;
						// position: absolute;
						// right: 0;
						background-image: icon(bullet-dot, color(normal, 040));
					}

					&.em {
						color: #ff3f94;
						
						strong {
							font-weight: inherit;
						}

						&::before {
							background-image: icon(bullet-dot, #ff3f94);
						}
					}
				}
			}
		}
	}

	.section.official-channel {
		
		// mobile
		@include respond-below(large-1x) {
			margin: 24rem 0;
		}

		// laptop
		@include respond-above(large-1x) {
			position: absolute;
			top: 140rem;
			right: 0;
			width: 520rem;
		}

		.section-head {
			@include hidden;
		}

		.section-body {
			.navi-list {	
				display: flex;
				justify-content: flex-end;	

				.navi-item {
					flex: 1 1 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					padding: 0 8rem;
					height: 56rem;
					@include font((outline: body, size: small-4x)...);
					border-radius: 8rem;
					background-color: color(normal, 010);
					

					// laptop
					@include respond-above(large-1x) {
						&:only-child {
							flex: 0 0 auto;
							width: 50%;
							box-sizing: border-box;
						}
					}

					+.navi-item {
						margin-left: 8rem;
					}

					// laptop
					@include respond-above(large-1x) {
						padding: 0 16rem;
						height: 72rem;
						border-radius: 15rem;
						@include font((outline: body, size: small-3x)...);

						+.navi-item {
							margin-left: 20rem;
						}
					}

					&::before {
						content: '';
						display: block;
						flex-shrink: 0;
						margin-right: 8rem;
					}

					.navi-text {
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						text-decoration: none;
						color: color(normal, 040);
						
						&::before {
							content: '';
							display: block;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
						}
						
						.wbr {
							display: block;
						}
					}

					&.adiga {
						&::before {
							width: 56rem;
							height: 16rem;
							background: url(../../assets/images/svg/adiga_logo.svg) no-repeat center/cover;

							// laptop
							@include respond-above(large-1x) {
								width: 88rem;
								height: 26rem;
							}
						}
					}

					&.kakao {
						&::before {
							width: 24rem;
							height: 24rem;
							background: url(../../assets/images/svg/kakao_logo.svg) no-repeat center/cover;

							// laptop
							@include respond-above(large-1x) {
								width: 36rem;
								height: 36rem;
							}
						}
					}
				}
			}
		}
	}

	.section.general-info {

		// mobile
		@include respond-below(large-1x) {
			margin: 24rem 0;
		}

		.section-head {
			@include hidden;
		}

		.section-body {
			.data-list {
				display: flex;
				flex-wrap: wrap;
				@include font((outline: body, size: small-2x)...);
				color: color(normal, 040);
				
				.data-item {
					margin-right: 20rem;

					.wbr {
						display: inline-block;
						vertical-align: top;
						
						// laptop
						@include respond-above(large-1x) {
							display: block;
						}
					}

					&:nth-child(1) {
						flex: 1 1 100%;
					}

					&:nth-child(n+2) {
						margin-top: 4rem;

						// laptop
						@include respond-above(large-1x) {
							margin-top: 8rem;
						}
					}
				}
			}
		}
	}

	.section.family-site {
		// mobile
		@include respond-below(large-1x) {
			margin: 24rem 0;
		}

		// laptop
		@include respond-above(large-1x) {
			position: fixed;
			z-index: 30;
			top: 0;
			display: flex;
			align-items: center;
			height: 104rem;
		}

		.section-wrap {
			.section-head {
				@include hidden;
			}
			.section-body {
				.navi-list {
					display: flex;
					// mobile
					@include respond-below(large-1x) {
						flex-direction: column;

						.navi-item+.navi-item {
							margin-top: 10rem;
						}
					}

					// mobile
					@include respond-above(large-1x) {						
						.navi-item+.navi-item {
							margin-left: 8rem;
						}
					}

	

					// mobile
					@include respond-below(large-1x) {
						.navi-item {
							display: flex;
							flex: 1 1 100%;

							.navi-text {
								flex: 1 1 100%;
								display: flex;
								align-items: center;
								justify-content: space-between;
								padding: 10rem 10rem 10rem 20rem;
								box-sizing: border-box;
								height: 40rem;
								@include font((outline: body, size: small-2x)...);
								color: color(normal, 000);
								text-decoration: none;
								border: 1rem solid color(normal,000);
								border-radius: 4rem;
			
								&::after {
									display: block;
									content: '';
									margin-left: 4rem;
									width: 20rem;
									height: 20rem;
									background-image: icon(chevron-right, color(normal, 000));
								}
							}
						}
					}

					// laptop
					@include respond-above(large-1x) {
						.navi-item {
							display: flex;

							.navi-text {
								display: flex;
								align-items: center;
								justify-content: space-between;
								// padding: 10rem 16rem;
								padding: 10rem 10rem 10rem 16rem;
								height: 40rem;
								@include font((outline: body, size: small-3x, weight: medium)...);
								color: color(accent, 010);
								text-decoration: none;
								white-space: nowrap;
								box-sizing: border-box;
								border: 1rem solid color(accent,010);
								border-radius: 20rem;

								&::after {
									display: block;
									content: '';
									// margin-left: 4rem;
									width: 18rem;
									height: 18rem;
									background-image: icon(chevron-right, color(accent, 010));
								}
							}

							// 22-12-08 신민철 추가
							&:nth-child(2) {
								.navi-text {
									background-color: color(accent,010);
									color: color(base,000);

									&::after {
										background-image: icon(chevron-right, color(base, 000));
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.copyright {
		margin: 24rem 0 0 0;
		@include font((outline: body, size: small-4x)...);
		color: color(normal, 030);
		
		// mobile
		@include respond-below(large-1x) {
			.wbr {
				display: inline-block;
				vertical-align: top;
			}
		}

		// laptop
		@include respond-above(large-1x) {
			display: flex;
			align-items: center;
			align-content: center;
			flex-wrap: wrap;
			height: 72rem;
			@include font((outline: body, size: small-2x)...);

			.wbr {
				margin-right: 0.25em;
				display: block;
			}
		}
	}
}

.section.module-b {
	&.style-b {
		padding: 20rem;
		border-radius: 16rem;
		box-sizing: border-box;
		background-color: color(accent, 020, 0.2);

		@include respond-above(large-1x) {
			padding: 32rem;
		}
	}

	.section-wrap {
		position: relative;

		.section-head {
			margin-bottom: 16rem;
			padding-bottom: 8rem;
			border-bottom: 1rem solid color(accent, 020);

			@include respond-above(large-1x) {
				margin-bottom: 24rem;
			}

			.section-subject {
				margin: 0;
				@include font((outline: body, size: small)...);
				color: color(normal, 100);

				@include respond-above(large-1x) {
					@include font((outline: head, size: small)...);
				}

				.section-name {
					display: block;
				}
			}
		}

		.section-body {
			.posts-latest {
				.info-board.module-d {
					display: flex;
					flex-direction: column;
					justify-content: center;

					&.attr-comment {
						.board-wrap {
							&::before {
								background-image: icon(attr-comment, color(accent, 020));
	
							}
							.board-subject {
								.board-name {
									color: color(accent, 020);
								}
							}
						}
					}
				}
			}
		}

		.section-util{
			position: absolute;
			top: 0;
			right: 0;

			.button-area {
				display: flex;
				column-gap: 8rem;

				.btn {
					&.more-data {
						border-radius: 50%;
						background-color: color(accent, 020);

						svg {
							fill: color(accent, 000);
						}
					}

					svg {
						display: block;
						width: 28rem;
						height: 28rem;
					}
				}
			}
		}
	}
}